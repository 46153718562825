import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { columnsEquipo } from './data';
import { toastNotification } from "helpers/utils";


export const DisabledEquipo = () => {

    const { user } = useAuth();

    const [ equiposDisabled, setEquiposDisabled] = useState([]);
    const [ enableEquipo, setEnableEquipo] = useState();
    const [ showEnableModal, setShowEnableModal] = useState(false);

    const startResetApp = async () => {
        getAllEquiposDisabled();
      }

      const getAllEquiposDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getAllEquipmentsDisabledByIdUser/${user.id}`;
        axios.get(url).then((response) => {
          const list = response.data.data
          list.forEach((element) => {
            element.acciones = [
              <IconButton
              title="Habilitar"
              variant="white"
              size="sm"
              icon="check"
              style={{ boxShadow: "none", width: "100%" }}
              onClick={() => {
                setShowEnableModal(true)
                setEnableEquipo(element.id)
              }}
              ></IconButton>
            ]
          })
          setEquiposDisabled(list);
        })
    }

        // HABILITAR
        const handleEnable = async () => {
            await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/enableEquipment/${enableEquipo}/${user.id}`)
            .then(() => {
              startResetApp()
              setShowEnableModal(false)
              }
            )
            .catch((err) => {
              toastNotification('error', 'No se pudo Habilitar el Equipo.', 2000)
              setTimeout(() => {
              }, 2000);
            })
        };

        useState( () => {
            startResetApp();
          }, [])
    

  return (
    <>
                { !equiposDisabled.length > 0 ? (
                <h6>No hay Equipos Deshabilitados</h6>
              ) :
              (
                <div>
                  <AdvanceTableWrapper
                    columns={columnsEquipo}
                    data={equiposDisabled}
                    sortable
                    pagination
                    perPage={10}
                  >
                    <AdvanceTable
                      title='Equipos Deshabilitados'
                      subtitle='Tabla de Equipos Deshabilitados'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={equiposDisabled.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                </div>
              )
            }

            {/* MODAL ENABLE */}
            <div>
        <Modal
          show={showEnableModal}
          onHide={() => setShowEnableModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Equipo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere Habilitar el Equipo?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEnableModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleEnable}
            >
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
