import IconButton from 'components/common/IconButton';
import React from 'react';

export const columns = [
  {
    accessor: 'accion',
    Header: 'Acciones'
  },
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'name',
    Header: 'Nombre'
  },
  {
    accessor: 'brand',
    Header: 'Marca'
  },
  {
    accessor: 'model',
    Header: 'Modelo'
  },
  {
    accessor: 'area',
    Header: 'Área'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
];

export const data = [
  {
    name: 'Bomba AP',
    type: 'Tipo',
    codeId: 1,
    brand: 'Slade',
    model: 'AKDDA-12-F28',
    description: 'Bomba de Marca Slade',
    accion: [
      <IconButton
        title='Ver Detalle'
        variant="white"
        size="lg"
        icon="eye"
        className='me-2'
        href="/vendedor/equipos/detalle"
        style={{boxShadow:'none', width:'100%'}}
      >
      </IconButton>
    ]
  },
  {
    name: 'Bomba KAD',
    tipo: 'Tipo',
    codigo: 2,
    marca: 'SealRYT',
    modelo: 'MODEL-SD-91D',
    descripcion: 'Nueva Bomba instalada',
    accion: [
      <IconButton
      title='Ver Detalle'
        variant="white"
        size="lg"
        icon="eye"
        className='me-2'
        href="/vendedor/equipos/detalle"
        style={{boxShadow:'none', width:'100%'}}
      >
      </IconButton>
    ]
  },
  {
    name: 'Demoledor A',
    tipo: 'Tipo',
    codigo: 3,
    marca: 'Jet-Lube',
    modelo: 'MODEL-01-18F',
    descripcion: 'Descripción del Demoledor A',
    accion: [
      <IconButton
      title='Ver Detalle'
        variant="white"
        size="lg"
        icon="eye"
        className='me-2'
        href="/vendedor/equipos/detalle"
        style={{boxShadow:'none', width:'100%'}}
      >
      </IconButton>
    ]
  }
];

export const columnsBitacora = [
  {
    accessor: 'id_usuario',
    Header: 'Id Usuario'
  },
  {
    accessor: 'nombre_usuario',
    Header: 'Nombre Usuario'
  },
  {
    accessor: 'apellido_usuario',
    Header: 'Apellido Usuario'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'fecha',
    Header: 'Fecha'
  },
  {
    accessor: 'hora',
    Header: 'Hora'
  },
  {
    accessor: 'nombre_evento',
    Header: 'Evento'
  },
  {
    accessor: 'id_data',
    Header: 'Id Data'
  }
]

export const columnsEquipo = [
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'name',
    Header: 'Nombre'
  },
  {
    accessor: 'description',
    Header: 'Descripción'
  },
  {
    accessor: 'codeId',
    Header: 'Código Id'
  },
  {
    accessor: 'serialNumber',
    Header: 'Número Serie'
  },
  {
    accessor: 'size',
    Header: 'Tamaño'
  },
  {
    accessor: 'rpm',
    Header: 'RPM'
  },
  {
    accessor: 'type',
    Header: 'Tipo Equipo'
  },
  {
    accessor: 'brand',
    Header: 'Marca'
  },
  {
    accessor: 'model',
    Header: 'Modelo'
  },
  {
    accessor: 'area',
    Header: 'Área'
  },
  {
    accessor: 'acciones',
    Header: 'Acciones'
  }
];