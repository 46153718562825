import React, { useEffect, useState } from 'react'
import { Card, Col, Nav, Row, Spinner } from 'react-bootstrap';
import { Outlet, Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import { isSectionOpen, registerAccessToSection, redirectByPermission } from 'pages/common/helpers';
import { sectionsCodes } from 'pages/common/data';

export const EquipoCliente = () => {

  const navigate = useNavigate();
  const { user } = useAuth()
  let params = useParams();

  const [ readyContent, setReadyContent ] = useState(false);
  const [equipo, setEquipo] = useState({
    id: 0,
    name: '',
    codeId: '',
    brand: '',
    model: '',
    rpm: '',
    serialNumber: '',
    size: '',
    line: '',
    description: '',
  });

  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/cliente/inicio");
    if(hasAccess) getDetallesEquipos();
    if( !isSectionOpen() ) navigate(redirectByPermission(user, params.id));
  }, []);

  const getDetallesEquipos = () => {
    if (user != null) {
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentById/${params.id}`).then((response) => {
        let equipment = response.data.data[0]
        setEquipo({
          id: equipment.id,
          name: equipment.name,
          codeId: equipment.codeId,
          brand: equipment.brand,
          model: equipment.model,
          rpm: equipment.rpm,
          serialNumber: equipment.serialNumber,
          size: equipment.size,
          line: equipment.line,
          description: equipment.description,
        });
      });
      setReadyContent(true)
    }
  }
  
  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>
      : (

        <Card.Body>
        <Row className="justify-content-between">
            <Col lg={4} className="ps-lg-4 my-5text-lg-start mt-4">
              <p className='m-0 text-secondary fs--1 fw-bold'>{equipo.line}</p>
              <p className="m-0 text-primary fs-2 fw-bold">{equipo.name}</p>
              <p className="m-0 text-secondary">{equipo.description}</p>
            </Col>
            <Col lg={8} className="ps-lg-4 my-5 text-lg-start">
              <Card>
                <Card.Body>
                  <Row className='text-center'>
                    <Col lg={2} className='d-flex text align-items-center justify-content-center mb-4'>
                      <Row className='mt-6'>
                        <FontAwesomeIcon icon={faWrench} className="text-secondary fs-6" style={{ width: '100%' }} />
                      </Row>
                    </Col>
                    <Col>
                      <Row className='mt-2' >
                        <h6 className="text-primary">Código ID:</h6>
                        <h5 className='text-secondary'>{equipo.codeId}</h5>
                      </Row>
                      <Row className='mt-4 mb-2' >
                        <h6 className="text-primary">N° de Serie:</h6>
                        <h5 className='text-secondary'>{equipo.serialNumber}</h5>
                      </Row>
                    </Col>
                    <Col>
                      <Row className='mt-2'>
                        <h6 className="text-primary">Marca:</h6>
                        <h5 className='text-secondary'>{equipo.brand}</h5>
                      </Row>
                      <Row className='mt-4 mb-2'>
                        <h6 className="text-primary">Modelo:</h6>
                        <h5 className='text-secondary'>{equipo.model}</h5>
                      </Row>
                    </Col>
                    <Col>
                      <Row className='mt-2'>
                        <h6 className="text-primary">R.P.M:</h6>
                        <h5 className='text-secondary'>{equipo.rpm}</h5>
                      </Row>
                      <Row className='mt-4 mb-2'>
                        <h6 className="text-primary">Tamaño:</h6>
                        <h5 className='text-secondary'>{equipo.size}</h5>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col lg={12} className="ps-lg-4 my-5 text-lg-start mt-4 mb-4 ">
              <Card>
                <Card.Body>
                  <Nav variant="tabs" className='mb-4' fill={true}>
                    {user.profileDimensional &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="dimensional"
                          eventKey="dimensional"
                          active={ (window.location.pathname.includes("dimensional") ? true : false) }
                          onClick={ () => (window.location.pathname.includes("dimensional") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.InformacionDimensional, Number(params.id))
                                  }
                        >Información Dimensional</Nav.Link>
                      </Nav.Item>
                    }
                    {user.profileOperational &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="operacional"
                          eventKey="operacional"
                          active={ (window.location.pathname.includes("operacional") ? true : false)}
                          onClick={ () => (window.location.pathname.includes("operacional") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.InformacionOperacional, Number(params.id))
                                  }
                        >Información Operacional</Nav.Link>
                      </Nav.Item>
                    }
                    {user.profileReport &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="reportes"
                          eventKey="reportes"
                          active={ (window.location.pathname.includes("reportes") ? true : false) }
                          onClick={ () => (window.location.pathname.includes("reportes") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.Reportes, Number(params.id))
                                  }
                        >Reportes</Nav.Link>
                      </Nav.Item>
                    }
                    {user.profileMessage &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="mensajes"
                          eventKey="mensajes"
                          active={ (window.location.pathname.includes("mensajes") ? true : false) }
                          onClick={ () => (window.location.pathname.includes("mensajes") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.Mensajes, Number(params.id))
                                  }
                        >Mensajes</Nav.Link>
                      </Nav.Item>
                    }
                    <Nav.Item className='col-12 col-lg-auto'>
                      <Nav.Link
                        as={Link}
                        to="literatura"
                        eventKey="literatura"
                        active={ (window.location.pathname.includes("literatura") ? true : false) }
                        onClick={ () => (window.location.pathname.includes("literatura") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.LiteraturaTecnica, Number(params.id))
                                  }
                      >Literatura Técnica</Nav.Link>
                    </Nav.Item>
                    {user.profileEconomic &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="mantenciones"
                          eventKey="mantenciones"
                          active={ (window.location.pathname.includes("mantenciones") ? true : false) }
                          onClick={ () => (window.location.pathname.includes("mantenciones") || user.idTypeProfile === 3) 
                                            ? null 
                                            : registerAccessToSection(sectionsCodes.AnalisisEconomico, Number(params.id))
                                  }
                        >Análisis Económico</Nav.Link>
                      </Nav.Item>
                    }
                    {user.profileEstadisticaEquipo &&
                      <Nav.Item className='col-12 col-lg-auto'>
                        <Nav.Link
                          as={Link}
                          to="estadistica"
                          eventKey="estadistica"
                          active={ (window.location.pathname.includes("estadistica") ? true : false) }
                        >Estádistica</Nav.Link>
                      </Nav.Item>
                    }
                  </Nav>
                  <Outlet />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      )
       }
    </>
  )
}
