import React, {useState, useEffect } from 'react'
import { Col, Form, Row, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";

const InputAtributosOperacional = ({ item, atributo = null, handleChangeAtributos, unidadesMedida }) => {
  
  const [ medidasOperacionales, setMedidasOperacionales ] = useState(unidadesMedida.filter((unidad) => unidad.tipoUnidadMedida !== "Longitud"));
  //! VALOR DEL INPUT
  const [ id, setId ] = useState((item.id) ? item.id : '')
  const [ value, setValue ] = useState((item.value) ? item.value : '');

  //! TIPO VALOR DEL INPUT
  const [ tipoValor, setTipoValor ] = useState({});

  useEffect( () => {
    if( item.value ){ // Si el item tiene valor, tenemos que actualizar.
      setValue(item.value);
      setTipoValor(item.measureUnit);
      // handleChangeAtributos({ value: item.value, id_atributo: item.attribute.id, tipoValor: item.tipoValor, [item.attribute.description]: item.attribute.description});
    }else{
      setValue('');
      if( item.attribute.typeMeasureUnit.descripcion === 'Lista' ){
        setTipoValor('Lista');
        return
      }
      setTipoValor(getTypeMedida());
    }
  }, [])

  //! OBTIENE LA DESCRIPCION DE LA PRIMERA UNIDAD DE MEDIDA COINCIDENTE;
  const getTypeMedida = () => {
    const medida = medidasOperacionales.find((medida) => item.attribute.typeMeasureUnit.id === medida.idTipoUnidadMedida)
    return medida ? medida : null;
  }

  //! CAMBIA EL VALOR DEL INPUT
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //! CAMBIA EL VALOR DEL TIPO
  const handleClick = (value) => {
    setTipoValor(value);
  };

  //! ENVIAR VALORES AL PADRE
  const handleSubmit = () => {
    let objToSend = { 
        id,
        value, 
        id_atributo: item.attribute.id, 
        idTipoUnidadMedida: ( tipoValor && tipoValor.id ) ? tipoValor.id : null, 
        [item.attribute.description]: item.attribute.description
      };
    handleChangeAtributos(objToSend);
  }


  const dropdownItems = unidadesMedida.map((unidad, index) => {
    if( unidad.tipoUnidadMedida === 'Longitud' || unidad.tipoUnidadMedida === 'Lista' ) return;
    if( item.attribute.typeMeasureUnit.id !== unidad.idTipoUnidadMedida ) return;
    
    return (
      <Dropdown.Item key={unidad.id} onClick={() => handleClick(unidad)} active={tipoValor.id === unidad.id}>
        {unidad.nombre}
      </Dropdown.Item> 
    )
  })

  return (
    ( !item.attribute.isDimensional ) &&
      <Form.Group className="mb-3">
          <Row>
              <Col>
                  <Form.Label>{item.attribute.description}:</Form.Label>
              </Col>
          <Col>
        
          <InputGroup className="mb-3" onBlur={handleSubmit}>

              { ( item.attribute.typeMeasureUnit.id === 4 ) ? (
                  /* ACA DEBEMOS MANDAR LA LISTA */
                  <Form.Select 
                          // required
                          name="Select" 
                          value={value} 
                          onChange={handleChange}
                      >
                          <option key={''} disabled={true} value="">Seleccione</option>

                          {item.attribute.listadoOpciones.map((option, index) => (
                              <option key={index} value={option.descripcion} >{option.descripcion}</option>
                            )
                          )}
                      </Form.Select>
                  
              ) : (
                  /* ACA DEBEMOS MANDAR EL INPUT */
                  <>
                      <Form.Control 
                        aria-label="Text input with dropdown button"
                        type="number" 
                        value={value}
                        onChange={ (e) => {handleChange(e)}}
                      />

                      <DropdownButton
                        variant="outline-secondary"
                        title={ ( tipoValor === null || tipoValor === undefined ) ? "Seleccione" : tipoValor.descripcion }
                        id="input-group-dropdown-2"
                        align="end"
                      >
                          { dropdownItems }
                      </DropdownButton>
                  </>
              ) }

          </InputGroup>

          </Col>
          </Row>
          <Form.Control.Feedback type="invalid">
              Por favor ingresar valor del Atributo.
          </Form.Control.Feedback>
      </Form.Group>
  )
}

export default InputAtributosOperacional;