import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification, verifyResponseUnauthorizedAwait, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from 'hooks/useAuth';
import { BiErrorCircle } from 'react-icons/bi';

import InputAtributos from '../../admin/equipos/components/InputAtributosDimensional'
import InputAtributosOperacional from '../../admin/equipos/components/inputAtributosOperacional'
import { convertStringsToUpperCase } from "helpers";

export const EditEquipo = ({data , handleChange, setShowEditModal}) => {


    const { user } = useAuth();
    const [validated, setValidated] = useState(false);

    const [ corporativos, setCorporativos] = useState([]);
    const [ plantas, setPlantas] = useState([]);
    const [ lineas, setLineas] = useState([]);
    const [ areas, setAreas] = useState([]);
    const [ tiposEquipos, setTiposEquipos] = useState([]);
    const [ marcas, setMarcas] = useState([]);
    const [ modelos, setModelos] = useState([]);
    const [ atributos, setAtributos ] = useState([]);
    const [ allSizesEquipos, setllSizesEquipos ] = useState([]);
    const [ unidadesMedida, setUnidadesMedida ] = useState([]);

    const [ name, setName ] = useState(data.name);
    const [ description, setDescription ] = useState(data.description);
    const [ codeId, setCodeId ] = useState(data.codeId);
    const [ serialNumber, setSerialNumber ] = useState(data.serialNumber);
    const [ size, setSize ] = useState(data.tamanoEquipo.id);
    const [ rpm, setRpm ] = useState(data.rpm);
    const [ idArea, setIdArea ] = useState(data.id_area);
    const [ idTipo, setIdTipo ] = useState(data.id_tipo_equipo);
    const [ idMarca, setIdMarca ] = useState(data.id_brand);
    const [ idModelo, setIdModelo ] = useState(data.id_model);
    const [ idCorporativo, setIdCorporativo ] = useState(data.id_corporativo);
    const [ idPlanta, setIdPlanta ] = useState(data.id_planta);
    const [ idLinea, setIdLinea ] = useState(data.id_linea);
    const [arreglo, setArreglo ] = useState([]);

    useEffect(() => {
      Promise.all([
        getAllUnidadesMedida(),
        getAllAreas(),
        getAllTiposEquipos(),
        getAllMarcas(),
        getModelos(data.id_brand),
        getAtributos(Number(data.id), Number(data.id_tipo_equipo)),
        getAllTamañosEquipos(),
      ])
      .then(([ allUnidadesMedida, allAreas, allTiposEquipos, allMarcas, allModelos, allAtributos, allTamanoEquipos ]) => {})
      .catch(error => {
        console.error("Error al intentar cargar la seccion");
        toastNotification('warning', 'Ha ocurrido un error al cargar los datos del formulario, por favor intentelo nuevamente');
      })
    }, [])

    const getAllUnidadesMedida = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/unidadMedida/getAllMeasureUnits`;
      try {
        const { data } = await axios.get(url);
        if( data.statusCode == 200 ) setUnidadesMedida(data.data);
      } catch (error) {
        console.error(error);
        console.error("Ha ocurrido un error al intentar consultar las unidades de medida");
      }
    };

    const getAllTamañosEquipos = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/getSizesEnabledAndDisabled`;
      try {
        const { data } = await axios.get(url);
        if(verifyResponseUnauthorizedAwait(data, 1)) return;
        if( data.statusCode == 200 ) setllSizesEquipos(data.data);
        if( data.statusCode !== 200 ) toastNotification('success', data.message);
      } catch (error) {
        console.error("Ha ocurrido un error al consultar los tamaños de los equipos");
        toastNotification('error', "Ha ocurrido un error interno al consultar los tamaños de equipos");
      }
    }

    const getAllAreas = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasByIdUser/${user.id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setAreas(list)
        }
      }).catch( error => {
        console.error("Ha ocurrido un error al obtener las areas");
      } );
    }

    const getAllTiposEquipos = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposDeEquipos`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setTiposEquipos(list)
        }
      }).catch( error => {
        console.error("Ha ocurrido un error al obtener los tipos de equipos");
      } );
    }

    const getAllMarcas = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/marca/getAllBrands`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setMarcas(list)
        }
      }).catch( error => {
        console.error("Error al intentar obtener las marcas");
      } );
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {

          let formData = {
            name: name,
            description: description,
            codeId: codeId,
            serialNumber: serialNumber,
            size: Number(size),
            rpm: rpm,
            id_area: Number(idArea),
            id_tipo: Number(idTipo),
            id_marca: Number(idMarca),
            id_modelo: Number(idModelo),
            id_emm: data.id_emm,
          }

          formData = convertStringsToUpperCase(formData);
          formData.atributos = arreglo;

          try {
            const resp = await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/updateEquipo/${data.id}/${user.id}`, formData);
            if( typeof resp !== 'object' ) {
              toastNotification("warning", "Error al intentar actualizar el equipo. Por favor, intentelo nuevamente");
              return;
            }
            if( verifyResponseUnauthorizedAwait(resp, 3) ) return;
            if( resp.data.statusCode == 200 ) {
              toastNotification("success", "Equipo editado correctamente");
              handleChange(true);
              setShowEditModal(false);
            }else if( resp.data.statusCode >= 400 && resp.data.statusCode <= 500 ){
              toastNotification('warning', 'Error interno al intentar editar el equipo, por favor intentelo nuevamente', 2000);
            }else{
              toastNotification('error', 'Error interno al intentar editar el equipo, por favor comunicarse con equipo de desarrollo');
            }
          } catch (error) {
            toastNotification('error', 'El Equipo no se pudo editar.', 2000)
          }
        }
        setValidated(true);
    };

    const handleChangeMarca = e => {
      const id = e.target.value;
      setIdMarca(id);
      if(id > 0){
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/modelo/getModelsByIdBrand/${id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setModelos(list)
        }else(setModelos([]))
      }).catch((err) => {
        console.error("Ha ocurrido un error al obtener los modelos de la marca");
        setModelos([])
      })
      }else{setModelos([])}
    }

    const getModelos = (id) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/modelo/getModelsByIdBrand/${id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setModelos(list)
        }else(setModelos([]))
      }).catch((err) => {
        console.error("Ha ocurrido un error al obtener los modelos de la marca")
        setModelos([])
      })
    }

    const handleChangeTipoEquipo = e => {
      const id = e.target.value;
      setIdTipo(id);
      if(id > 0){
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getAttributeTypeEquiment/${id}`;
      axios.get(url).then((response) => {
        if(response.data.statusCode === 200){
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          const list =  response.data.data;
          setAtributos(list)
        }else{setAtributos([])}
      }).catch((err) => {
        console.error('Error al obtener los atributos del tipo de equipo');
        setAtributos([])
      })
    }else{
      setAtributos([])
    }
    }

    const getAtributos = (idEquipo, idTipoEquipo) => {
      if(idEquipo > 0 && idTipoEquipo > 0){
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getEquipmentValueAttribute/${idEquipo}/${idTipoEquipo}`;
        axios.get(url).then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if(response.data.statusCode === 200){
            const list =  response.data.data;
            setArreglo(list);
            setAtributos(list)
          }else{setAtributos([])}
        }).catch((err) => {
          console.error("Error al obtener los atributos del equipo");
          setAtributos([])
        })
      }else{
        setAtributos([])
      }
    }

    const handleChangeAtributos = (item) => { //!!!!! VERIFICAR
      if( item.idTipoUnidadMedida == null && !item.is_lista ) return;

      if (item.value === "") {
        // Si el item viene sin valor, se elimina del arreglo
        setArreglo((prevAtributos) =>
          arreglo.filter((atributo) => atributo.id_atributo !== item.id_atributo)
        );
        return;
      }
  
      const index = arreglo.findIndex(
        (element) => element.id_atributo === item.id_atributo
      );
  
      if (index !== -1) {
        const newArreglo = [...arreglo];
        newArreglo[index] = item;
        setArreglo(newArreglo);
      } else {
        setArreglo([...arreglo, item]);
      }
    }


  return (
    <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
        <Col sm={12} lg={6}>
        <Form.Group className="mb-3">
            <Form.Label>Nombre: <BiErrorCircle className="ms-2" /></Form.Label>
            <Form.Control
                type="text"
                name='name'
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Descripción: <BiErrorCircle className="ms-2" /></Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={1} 
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Código Id: <BiErrorCircle className="ms-2" /></Form.Label>
            <Form.Control
                type="text"
                name='codeId'
                value={codeId}
                required
                onChange={(e) => setCodeId(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Código Id.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Número de Serie: <BiErrorCircle className="ms-2" /></Form.Label>
            <Form.Control
                type="text"
                name='serialNumber'
                value={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Número de Serie.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="d-flex align-items-center">
              Tamaño:
              <BiErrorCircle className="ms-2" />
            </Form.Label>
          <Form.Select
            name='size'
            value={size}
            required
            onChange={(e) => setSize(e.target.value)}
          >
            <option value="">Seleccionar tamaño</option>
            {allSizesEquipos.map((tam) => (
              <option disabled={(!tam.estado) ? true : false} key={tam.id} value={tam.id}>{tam.descripcion}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor Seleccione un Tamaño.
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mb-3">
            <Form.Label>RPM: <BiErrorCircle className="ms-2" /></Form.Label>
            <Form.Control
                type="text"
                name='rpm'
                value={rpm}
                required
                onChange={(e) => setRpm(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar RPM.
            </Form.Control.Feedback>
        </Form.Group>
        </Col>

        <Col sm={12} lg={6}>

          {/* <Form.Group className="mb-3">
              <Form.Label>Área: <BiErrorCircle className="ms-2" /></Form.Label>
              <Form.Select 
                  required
                  name="id_area" 
                  value={idArea} 
                  onChange={(e) => setIdArea(e.target.value)}>
                  <option value=''>Seleccione Área</option>
                  {areas.map((item) => 
                      <option 
                          name="area" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Área.
              </Form.Control.Feedback>
          </Form.Group> */}

          <Form.Group className="mb-3">
              <Form.Label>Marca: <BiErrorCircle className="ms-2" /></Form.Label>
              <Form.Select 
                  required
                  name="id_marca" 
                  value={idMarca} 
                  onChange={handleChangeMarca}>
                  <option value=''>Seleccione Marca</option>
                  {marcas.map((item) => 
                      <option 
                          name="marca" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Marca.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Label>Modelo: <BiErrorCircle className="ms-2" /></Form.Label>
              <Form.Select 
                  required
                  name="id_modelo" 
                  value={idModelo} 
                  onChange={(e) => setIdModelo(e.target.value)}>
                  <option value=''>Seleccione Modelo</option>
                  {modelos.map((item) => 
                      <option 
                          name="modelo" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Modelo.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Label>Tipo Equipo: <BiErrorCircle className="ms-2" /></Form.Label>
              <Form.Select 
                  disabled
                  required
                  name="id_tipo" 
                  value={idTipo} 
                  onChange={handleChangeTipoEquipo}>
                  <option value=''>Seleccione Tipo Equipo</option>
                  {tiposEquipos.map((item) => 
                      <option 
                          name="tipo_equipo" 
                          key={item.id} 
                          value={item.id}>
                              {item.name}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Tipo Equipo.
              </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {atributos.length > 0 ? 
        <Col>
          <div>
            <Card className={'p-3 mb-3'}>
              <h5 className="mb-5">Atributos Operacionales</h5>

                {atributos.map((item) => (
                  <InputAtributosOperacional id={item.id} key={item.id} item={item} handleChangeAtributos={handleChangeAtributos} unidadesMedida={unidadesMedida} />  
                ))}

            </Card>

            <Card className={'p-3 mb-3'}>
            <h5 className="mb-5">Atributos Dimensionales</h5>
              {atributos.map((item) => {
                return (
                  <>
                    <InputAtributos id={item.id} key={item.id} item={item} handleChangeAtributos={handleChangeAtributos} unidadesMedida={unidadesMedida} />
                  </>
                )
              })}
            </Card>


          </div>
          </Col>
        : ''}

        </Row>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
