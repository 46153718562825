import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification, verifyResponseUnauthorizedAwait, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from 'hooks/useAuth';
import InputAtributos from './equipo/components/InputAtributosDimensional'
import InputAtributosOperacional from './equipo/components/inputAtributosOperacional'
import { convertStringsToUpperCase } from "helpers";

export const CreateEquipo = ({ handleChange, setShowCreateModal}) => {

    const { user } = useAuth();
    const [validated, setValidated] = useState(false);

    const [ areas, setAreas] = useState([]);
    const [ tiposEquipos, setTiposEquipos] = useState([]);
    const [ marcas, setMarcas] = useState([]);
    const [ modelos, setModelos] = useState([]);
    const [ atributos, setAtributos ] = useState([]);
    const [ allSizesEquipos, setllSizesEquipos ] = useState([]);
    const [ unidadesMedida, setUnidadesMedida ] = useState([]);

    const [ name, setName ] = useState();
    const [ description, setDescription ] = useState();
    const [ codeId, setCodeId ] = useState();
    const [ serialNumber, setSerialNumber ] = useState();
    const [ size, setSize ] = useState();
    const [ rpm, setRpm ] = useState();
    const [ idArea, setIdArea ] = useState();
    const [ idTipo, setIdTipo ] = useState();
    const [ idMarca, setIdMarca ] = useState();
    const [ idModelo, setIdModelo ] = useState();
    const [arreglo, setArreglo ] = useState([]);

    useEffect(() => {
      getAllAreas();
      getAllTiposEquipos();
      getAllMarcas();
      getAllTamañosEquipos();
      getAllUnidadesMedida();
    }, [])

    const getAllUnidadesMedida = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/unidadMedida/getAllMeasureUnits`;
      try {
        const { data } = await axios.get(url);
        if( verifyResponseUnauthorizedAwait(data, 1) ) return;
        if( data.statusCode == 200 ) setUnidadesMedida(data.data)
      } catch (error) {
        console.error("Error al obtener las unidades de medida");
      }
    };

    const getAllTamañosEquipos = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/getSizesEnabledAndDisabled`;
      try {
        const { data } = await axios.get(url);
        if( verifyResponseUnauthorizedAwait(data, 1) ) return;
        if( data.statusCode == 200 ) setllSizesEquipos(data.data);
        if( data.statusCode !== 200 ) toastNotification('success', data.message);
      } catch (error) {
        console.error("Error al obtener los tamaños de equipos")
      }
    }

    const getAllAreas = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasByIdUser/${user.id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setAreas(list)
        }
      }).catch( error => {
        console.error("Error al obtener las areas");
      } );
    }

    const getAllTiposEquipos = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposDeEquipos`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setTiposEquipos(list)
        }
      }).catch( error => {
        console.error("Error al obtener los tipos de equipos");
      } );
    }

    const getAllMarcas = () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/marca/getAllBrands`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setMarcas(list)
        }
      }).catch( error => {
        console.error("Error al obtener las marcas");
      } );
    }

    const handleSubmit = async(e) => {
      e.preventDefault();
      if (idTipo === undefined) {
        toastNotification("error", "El tipo de equipo es obligatorio");
        return;
      }

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        let formData = {
          name: name,
          description: description,
          codeId: codeId,
          serialNumber: serialNumber,
          size: Number(size),
          rpm: rpm,
          id_area: Number(idArea),
          id_tipo: Number(idTipo),
          id_marca: Number(idMarca),
          id_modelo: Number(idModelo),
          id_usuario: Number(user.id),
        }

        formData = convertStringsToUpperCase(formData);
        formData.atributos = arreglo;
        
        await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/createEquipment/`, formData)
          .then(( response ) => {
            if( verifyResponseUnauthorizedFetch(response, 2) ) return;
            handleChange(true)
            setShowCreateModal(false);
          })
          .catch((err) => {
            console.error(err)
            toastNotification('error', 'El Equipo no se pudo agregar.', 2000)
            })
          }
          setValidated(true);
    };

    const handleChangeMarca = e => {
      const id = e.target.value;
      setIdMarca(id);
      if(id > 0){
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/modelo/getModelsByIdBrand/${id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setModelos(list)
        }else(setModelos([]))
      }).catch((err) => {
        console.error("Error al obtener los modelos de la marca");
        setModelos([])
      })
    }else{setModelos([])}
    }

    const handleChangeTipoEquipo = e => {
      setArreglo([]);
      const id = e.target.value;
      setIdTipo(id);
      if(id > 0){
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/findAttributeTypeEquipment2/${id}`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list = response.data.data;          
          setAtributos(list)
        }else{setAtributos([])}
      }).catch((err) => {
        setAtributos([])
      })
    }else{
      setAtributos([])
    }
    }

    const handleChangeAtributos = (item) => {

      if(item.value === '' ){ // Si el item viene sin valor, se elimina del arreglo
        setArreglo( (prevAtributos) => arreglo.filter( ( atributo ) => atributo.id_atributo !== item.id_atributo ) );
        return
      }
      const index = arreglo.findIndex(element => element.id_atributo === item.id_atributo);

      if(index !== -1){
        const newArreglo = [...arreglo];
        newArreglo[index] = item;
        setArreglo(newArreglo);
      }else{
        setArreglo([     
          ...arreglo,
        item
        ]);
      }
    }

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Col sm={12} lg={6}>
        <Form.Group className="mb-3">
            <Form.Label>Nombre: </Form.Label> 
            <Form.Control
                type="text"
                name='name'
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={1} 
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Código Id:</Form.Label>
            <Form.Control
                type="text"
                name='codeId'
                value={codeId}
                required
                onChange={(e) => setCodeId(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Código Id.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Número de Serie:</Form.Label>
            <Form.Control
                type="text"
                name='serialNumber'
                value={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Número de Serie.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Tamaño:</Form.Label>
            <Form.Control
              as="select"
              name='size'
              value={size}
              required
              onChange={(e) => setSize(e.target.value)}
            >

              {allSizesEquipos.map((size) => {
                if( size.estado ) return (
                  <option key={size.id} value={size.id}>{size.descripcion}</option>
                )
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                Por favor Seleccione un Tamaño.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>RPM:</Form.Label>
            <Form.Control
                type="text"
                name='rpm'
                value={rpm}
                required
                onChange={(e) => setRpm(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar RPM.
            </Form.Control.Feedback>
        </Form.Group>
        </Col>

        <Col sm={12} lg={6}>

          <Form.Group className="mb-3">
              <Form.Label>Área:</Form.Label>
              <Form.Select 
                  required
                  name="id_area" 
                  value={idArea} 
                  onChange={(e) => setIdArea(e.target.value)}>
                  <option value=''>Seleccione Área</option>
                  {areas.map((item) => 
                      <option 
                          name="area" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Área.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Label>Marca:</Form.Label>
              <Form.Select 
                  required
                  name="id_marca" 
                  value={idMarca} 
                  onChange={handleChangeMarca}>
                  <option value=''>Seleccione Marca</option>
                  {marcas.map((item) => 
                      <option 
                          name="marca" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Marca.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Label>Modelo:</Form.Label>
              <Form.Select 
                  required
                  name="id_modelo" 
                  value={idModelo} 
                  onChange={(e) => setIdModelo(e.target.value)}>
                  <option value=''>Seleccione Modelo</option>
                  {modelos.map((item) => 
                      <option 
                          name="modelo" 
                          key={item.id} 
                          value={item.id}>
                              {item.nombre}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Modelo.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Label>Tipo Equipo:</Form.Label>
              <Form.Select 
                  required
                  name="id_tipo" 
                  value={idTipo} 
                  onChange={handleChangeTipoEquipo}>
                  <option value=''>Seleccione Tipo Equipo</option>
                  {tiposEquipos.map((item) => 
                      <option 
                          name="tipo_equipo" 
                          key={item.id} 
                          value={item.id}>
                              {item.name}
                      </option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  Por favor seleccione Tipo Equipo.
              </Form.Control.Feedback>
          </Form.Group>
        </Col>

        
        {atributos.length > 0 ? 
        <Col>
          <div>
            <Card className={'p-3 mb-3'}>
              <h5 className="mb-5">Atributos Operacionales</h5>
                {atributos.map((item) => {
                  return(
                    <InputAtributosOperacional id={item.id} key={item.id} item={item} handleChangeAtributos={handleChangeAtributos} unidadesMedida={unidadesMedida} />
                  )

                })}

            </Card>

            <Card className={'p-3 mb-3'}>
            <h5 className="mb-5">Atributos Dimensionales</h5>
              {atributos.map((item) => {
                return (
                  <>
                    <InputAtributos id={item.id} key={item.id} item={item} handleChangeAtributos={handleChangeAtributos} unidadesMedida={unidadesMedida} />
                  </>
                )
              })}
            </Card>


          </div>
          </Col>
        : ''}

        </Row>
        

        <div>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};
