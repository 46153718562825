import axios from 'utils/axios';
import React, { useEffect,  useState } from 'react'

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import { showAlertaTokenExpired, toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import IconButton from 'components/common/IconButton';

import BitacoraEntidad from '../components/BitacoraEntidad'
import BitacoraData from '../components/BitacoraData.js'
import ModalTipoMensaje from './components/ModalTipoMensaje.js';
import ModalDesabilitados from './components/ModalDesabilitados.js';

import { columnsTiposMensajes } from '../data.js';
import useLoadingContext from 'hooks/useLoadingContext.js';

const TiposMensajesAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);

  const [ tiposMensajes, setTiposMensajes ] = useState([]);
  const [ tiposMensajesToTable, setTiposMensajesToTable ] = useState([]);
  const [ dataBitacoraEntity, setDataBitacoraEntity ] = useState([]);
  const [ idToDelete, setIdToDelete ] = useState(0);
  const [ idToUpdate, setIdToUpdate ] = useState(0);

  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
  const [ showBitacoraData, setShowBitacoraData ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ showTipoMensajeModal, setShowTipoMensajeModal ] = useState(false);
  
  const [ tipoMensajeToUpdate, setTipoMensajeToUpdate ] = useState({});
  const [ showModalDisabled, setShowModalDisabled ] = useState(false);

  //! Bitacoras
  const [ idEntidad, setIdEntidad ] = useState(7);
  const [ idBitacoraData, setIdBitacoraData ] = useState([]);


  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileTipoMensajeAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  //! ADAPTA LOS TIPOS DE MENSAJES PARA SER MOSTRANDOS EN LA TABLA
  useEffect( () => {
    const tiposMensajesButton = tiposMensajes.map( tipo => {
      tipo.acciones = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowTipoMensajeModal(true)
            setTipoMensajeToUpdate(tipo)
            setIdToUpdate(tipo.id)
          }}
        ></IconButton>,
        <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowDeleteModal(true)
            setIdToDelete(tipo.id)
          }}
        ></IconButton>,
        <IconButton
            title="Ver Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={ () => {
              setIdBitacoraData(tipo.id)
              setShowBitacoraData(true)
            }}
        ></IconButton>
      ];
      return tipo
    } )
    setTiposMensajesToTable(tiposMensajesButton);
  }, [ tiposMensajes ])
  
  const startResetApp = () => {
    setIdToUpdate(0);
    setIdToDelete(0)
    setTipoMensajeToUpdate({})
    Promise.all([ getAllTypesMensajes() ])
      .then(([ allTypeMessage ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
    })
  }
  
  const getAllTypesMensajes = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/getAllTiposMensajes`;
    try {
      const { data } = await axios.get(url);
      setReadyContent(true)
      if( !data || data === undefined || data.statusCode === 401 ) {
        showAlertaTokenExpired();
        return;
      }
      if(data.statusCode == 200) setTiposMensajes(data.data)
    } catch (error) {
      console.error('Error al intentar obtener todos los tipos de mensajes');
      setReadyContent(true)
    }
  }

  //! ACTUALIZAR UN TIPO DE MENSAJE
  const updateTipoMensaje = async ( tipoMensaje ) => {
    const { id, description } = tipoMensaje;
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/updateTipoMensajeById/${id}`;
    try {
      setIsLoading(true);
      const { data }  = await axios.patch(url, {description});
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tipo de mensaje editado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al editar el tipo de mensaje')
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar actualizar el tipo de mensaje');
      toastNotification('error', 'Ocurrió un error al editar el tipo de mensaje')
    }
    startResetApp()
    setShowTipoMensajeModal(false)
  }

  //! CREAR NUEVO TIPO DE MENSAJE
  const createNewTipoMensaje = async ( tipoMensaje ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/createNewTipoMensaje`;
    try {
      setIsLoading(true)
      const { data } = await axios.post(url, tipoMensaje)
      setIsLoading(false);
    
      if( verifyResponseUnauthorizedAwait(data, 2) ) return;
      if( data.statusCode == 201 ) toastNotification('success', 'Tipo de mensaje creado correctamente')
      if( data.statusCode !== 201 ) toastNotification('warning', 'Ocurrió un error al crear el tipo de mensaje')
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar crear el tipo de mensaje');
      toastNotification('error', 'Ocurrió un error al crear el tipo de mensaje')
    }
    startResetApp()
    setShowTipoMensajeModal(false)
  }

  //! HABILITAR UN TIPO DE MENSAJE POR SU ID
  const enableTipoMensajeById = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/enableTipoMensajeById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url)
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200) toastNotification('success', 'Tipo de mensaje habilitado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar habilitar el tipo de mensaje')
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar habilitar el tipo de mensaje');
      toastNotification('error', 'Ocurrió un error al habilitar el tipo de mensaje')
    }
    startResetApp()
    setShowModalDisabled(false);
  }

  //! DESHABILITAR UN TIPO DE MENSAJE POR SU ID
  const deleteTipoMensajeById = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/disableTipoMensajeById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 4) ) return;
      if(data.statusCode == 200 ) toastNotification('success', 'Tipo de mensaje deshabilitado correctamente');
      if(data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al deshabilitar el tipo de mensaje');
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar deshabilitar el mensaje');
      toastNotification('error', 'Ocurrió un error al deshabilitar el tipo de mensaje')
    }
    startResetApp();
    setShowDeleteModal(false)
  }

  return (
    <>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="justify-content-between">
          <Col lg={6} className="ps-lg-4 my-5text-lg-start">
            <h3 className="text-primary">Tipos de Mensajes</h3>
          </Col>
        </Row>

        { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>   
        : (
        <Card className="h-md-100">
          <Card.Body>

          <AdvanceTableWrapper
            columns={columnsTiposMensajes}
            data={tiposMensajesToTable}
            sortable
            pagination
            perPage={5}
          >

          <Row className="flex-end-center mb-3">
            <Col xs={12} lg={4} className='mx-auto mb-2'>
              <div className='text-center'>
                <IconButton
                  variant="falcon-success"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                    setShowTipoMensajeModal(true)
                  }}
                >
                  Nuevo Tipo de Mensaje
                </IconButton>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mx-auto mb-2'>
              <div className='text-center'>
                <IconButton
                  variant="falcon-primary"
                  size="sm"
                  icon="book"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                    setShowBitacoraEntidad(true)
                  }}
                >
                  Bitácora General
                </IconButton>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mx-auto mb-2'>
              <div className='text-center'>
                <IconButton
                  variant="falcon-primary"
                  size="sm"
                  icon="trash"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                    setShowModalDisabled(true)
                  }}
                >
                  Deshabilitados
                </IconButton>
              </div>
            </Col>
          </Row>
          
          <AdvanceTable
            title='Tipos de Mensajes'
            subtitle='Tabla Tipos de Mensajes'
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={tiposMensajes.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
          </Card.Body>
        </Card>
        ) }
      </Card.Body>

      {/* MODALES */}
      <div>
        <BitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
      </div>

      <div>
        <BitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
      </div>

      <div>
          <ModalTipoMensaje showTipoMensajeModal={showTipoMensajeModal} setShowTipoMensajeModal={setShowTipoMensajeModal} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} tipoMensajeToUpdate={tipoMensajeToUpdate} setTipoMensajeToUpdate={setTipoMensajeToUpdate} createNewTipoMensaje={createNewTipoMensaje} updateTipoMensaje={updateTipoMensaje}/>
      </div>


      <div>
          <ModalDesabilitados showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableTipoMensajeById={enableTipoMensajeById}/>
      </div>

      <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Tipo de Mensaje</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de deshabilitar el tipo de mensaje?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={isLoading}
              onClick={() => {
                setShowDeleteModal(false)
                setIdToDelete(0)
              }}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger"
              disabled={isLoading}
              onClick={() => { 
                deleteTipoMensajeById(idToDelete)
              }}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}

    </>
  )
}

export default TiposMensajesAdmin