import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";

const InputAtributos = ({ item, handleChangeAtributos, unidadesMedida }) => {
  
  //! VALOR DEL INPUT
  const [ id, setId ] = useState((item.id) ? item.id : '')
  const [ value, setValue ] = useState((item.value) ? item.value : '');
  //! TIPO VALOR DEL INPUT
  const [ tipoValor, setTipoValor ] = useState({});

  useEffect( () => {
    if( item.value ){
      setValue(item.value);
      setTipoValor(item.measureUnit);
    }else{
      setValue('');
      setTipoValor((item?.attribute?.typeMeasureUnit?.descripcion == 'Longitud' || item?.attribute?.typeMeasureUnit?.descripcion == 'Lista' ) ? unidadesMedida[0] : '');
    }
  }, []);

  //! CAMBIA EL VALOR DEL INPUT
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //! CAMBIA EL VALOR DEL TIPO
  const handleClick = (value) => {
    setTipoValor(value);
  };

  //! ENVIAR VALORES AL PADRE
  const handleSubmit = () => {
    let objToSend = { 
        id, 
        value, 
        id_atributo: item.attribute.id, 
        idTipoUnidadMedida: tipoValor.id,
        [item.attribute.description]: item.attribute.description
      };
    handleChangeAtributos(objToSend);
  };

  
  const dropdownItems = unidadesMedida.map((unidad, index) => {
    if( unidad.idTipoUnidadMedida !== 1) return;
    if( tipoValor == null) return;

    return (
      <Dropdown.Item key={unidad.id} onClick={() => handleClick(unidad)} active={tipoValor.id === unidad.id}>
        {unidad.nombre}
      </Dropdown.Item> 
    )
  })

  return (
    (item.attribute.isDimensional) &&
                  
    <Form.Group className="mb-3">
        <Row>
          <Col>
            <Form.Label>{item.attribute.description}:</Form.Label>
          </Col>
          <Col>
        
            <InputGroup className="mb-3" onBlur={handleSubmit}>
                <Form.Control 
                  aria-label="Text input with dropdown button"
                  type="number"
                  value={value}
                  onChange={ (e) => {handleChange(e)} }
                />

                <DropdownButton
                  variant="outline-secondary"
                  title={ ( tipoValor === null || tipoValor === undefined ) ? "Seleccione" : tipoValor.descripcion }
                  id="input-group-dropdown-2"
                  align="end"
                >
                  { dropdownItems }
                </DropdownButton>
            </InputGroup>

          </Col>
        </Row>

    </Form.Group>
  )
}

export default InputAtributos