import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Container, Spinner } from 'react-bootstrap';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { columns, data} from './data'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import { useNavigate } from 'react-router-dom';
import { getSixWords, toastNotification } from 'helpers/utils';

const EquiposCliente = () => {

  const { user } = useAuth()

  const[ corporationList, setCorporationList] = useState([]);
  const[ floorList, setFloorList ] = useState([]); 
  const[ lineList, setLineList ] = useState([]); 
  const[ areaList, setAreaList ] = useState([]); 
  const[ equipmentToTable, setEquipmentToTable ] = useState([]);
  const[ equipmentList, setEquipmentList ] = useState([]);
  const[ idCorporationSelect, setIdCorporationSelect] = useState();
  const[ idFloorSelect, setIdFloorSelect] = useState();
  const[ idlineSelect, setIdLineSelect] = useState();

  const [ readyContent, setReadyContent ] = useState(false);

  let navigate = useNavigate(); 
  const routeChange = (id) =>{ 
    let path = `/cliente/equipo/${id}`; 
    navigate(path);
    
  }

  //listar equipos
  useEffect(() => {
    getEquiposOfUser(); // Obtener los equipos relacionados al usuario;
    getCorporativosOfUser(); // Obtiene los corporativos relacionados al usuario para poder cargar el select
  },[])

  const getEquiposOfUser = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}`);
      setReadyContent(true);
      if( data.statusCode === 200 ) {
        setEquipmentList(data.data);
        return;
      }
      if( data.data === 204 ) toastNotification('warning', 'No se han encontrado equipos asignados');
    } catch (error) {
      toastNotification('error', 'Ha ocurrido un error al intentar obtener los equipos asignados al usuario');
      console.error('Ha ocurrido un error al intentar obtener los equipos asignados al usuario');
    }
  }

  const getCorporativosOfUser = async () => {
    if( user === null ) return;
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/corporation/getCorporationByIdUser/${user.id}`);
      if( data.statusCode === 200 ) setCorporationList(data.data);
    } catch (error) {
      setCorporationList([]);
      console.log(error);
    }
  }

  // Formatear equipos para la tabla --- Se ejecuta siempre que tengamos nuevos equipos en la lista
  useEffect( () => {
    if( equipmentList.length < 1 ) return;

    const equiposToTable = equipmentList.map( equipo => {
      const toReturn = { ...equipo };
      toReturn.description = ( equipo.description.length > 0 ) ? getSixWords(equipo.description) : ''
      toReturn.accion = [
        <IconButton
          title='Ver Detalle'
          variant="white"
          size="lg"
          icon="eye"
          className='me-2'
          onClick={() => {
            registryAccessToEquipment(equipo.id)
            routeChange(equipo.id)
          }}
          style={{boxShadow:'none', width:'100%'}}
        >
        </IconButton>
      ];
      return toReturn;
    } );
    setEquipmentToTable(equiposToTable);
  }, [equipmentList] )
  

  // Registra el acceso de un usuario a un equipo
  const registryAccessToEquipment = async ( idEquipo = 0 ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/registryAccessToEquipment`;
    try {
      const { data } = await axios.post(url, { idEquipo });
      if( data.statusCode !== 201 ) console.error('Al parecer ha ocurrido un error al intentar registrar el acceso al equipo');
    } catch (error) {
      console.error("Error al intentar registrar el acceso al equipo");
    }
  }
  
  
  // Seleccionar corporativo, cargar select planta, listar equipos con filtro por planta
  const  handleChangeCorporation = function (e){
      const idCorporation = e.target.value;
      setIdCorporationSelect(idCorporation);
      if (idCorporation > 0) {
        axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/floor/getFloorByIdUserAndCorporation/${user.id}/${idCorporation}`).then((response) => {
          setFloorList(response.data.data);
        });
        axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporation}`).then(({data}) => {
          const list = data.data;
          setEquipmentList(list)
          setLineList([]);
          setAreaList([]);
        }).catch((err) => {
          setEquipmentList([]);
          setLineList([]);
          setAreaList([]);
        })
      }else{
          setFloorList([]);
          setLineList([]);
          setAreaList([]);
          setIdCorporationSelect();
          axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}`).then(({data}) => {
            const list = data.data;
            setEquipmentList(list)
          });
      }
  }

  //seleccionar planta y cargar select línea
  const  handleChangeFloor = function (e){
    const idFloor = e.target.value;
    setIdFloorSelect(idFloor)
    if (idFloor > 0) {
      axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/line/getLineByIdUserAndFloor/${user.id}/${idFloor}`).then((response) => {
        setLineList(response.data.data);
      });
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}/${idFloor}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
        setAreaList([]);
      }).catch((err) => {
        setEquipmentList([]);
        setAreaList([]);
      })
    }else{
      setLineList([]);
      setAreaList([]);
      setIdFloorSelect();
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
      });
    }
}

  //cargar select area al seleccionar línea
  const  handleChangeLine = function (e){
    const idLine = e.target.value;
    setIdLineSelect(idLine)
    if (idLine > 0) {
      axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/area/getAreaByIdUserAndLine/${user.id}/${idLine}`).then((response) => {
        setAreaList(response.data.data);
      });
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}/${idFloorSelect}/${idLine}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
      }).catch((err) => {
        setEquipmentList([]);
      })
    }else{
      setAreaList([]);
      setIdLineSelect()
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}/${idFloorSelect}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
      });
    }
  }

  //cargar select area al seleccionar línea
  const  handleChangeArea = function (e){
    const idArea = e.target.value;
    if (idArea > 0) {
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}/${idFloorSelect}/${idlineSelect}/${idArea}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
      }).catch((err) => {
        setEquipmentList([]);
      });
    }else{
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${user.id}/${idCorporationSelect}/${idFloorSelect}/${idlineSelect}`).then(({data}) => {
        const list = data.data;
        setEquipmentList(list)
      });
    }
  }

  return (
    <Card.Body className="overflow-hidden p-lg-6">
        <Row className="justify-content-between">
          <Col lg={6} className="ps-lg-4 my-5text-lg-start">
            <h3 className="text-primary">Equipos</h3>
          </Col>
        </Row>
        { (!readyContent) ? ( <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>) : (
          <>
                <Card>
                  <Card.Body className="h-md-100 ms-4 me-4">
                    <Container fluid="md">

                      <Row className='mb-3'>

                        <Col className='mb-3' xs={12} md={6}>
                          <Col>
                            <h5 className='text-primary' >Corporativo</h5>
                          </Col>
                          <Col>
                            <Form.Select aria-label="Default select example" onChange={handleChangeCorporation}>
                              <option value={-1}>Seleccione Corporativo</option>
                              {corporationList.map((corporation) => <option key={corporation.id} value={corporation.id}>{corporation.name}</option>)}
                            </Form.Select>
                          </Col>
                        </Col>

                        <Col xs={12} md={6}>
                          <Col>
                            <h5 className='text-primary'>Planta</h5>
                          </Col>
                          <Col>
                            <Form.Select aria-label="Default select example" onChange={handleChangeFloor}>
                              <option value={-1}>Seleccione Planta</option>
                              {floorList.map((floor) => <option key={floor.id} value={floor.id}>{floor.name}</option>)}
                            </Form.Select>
                          </Col>
                        </Col>

                      </Row>

                      <Row className='mb-3'>

                        <Col className='mb-3' xs={12} md={6}>
                          <Col>
                            <h5 className='text-primary'>Línea</h5>
                          </Col>
                          <Col>
                            <Form.Select aria-label="Default select example" onChange={handleChangeLine}>
                              <option value={-1}>Seleccione Línea</option>
                              {lineList.map((line) => <option key={line.id} value={line.id}>{line.description}</option>)}
                            </Form.Select>
                          </Col>
                        </Col>

                        <Col xs={12} md={6}>
                          <Col>
                            <h5 className='text-primary'>Área</h5>
                          </Col>
                          <Col>
                            <Form.Select aria-label="Default select example" onChange={handleChangeArea}>
                              <option value={-1}>Seleccione Área</option>
                              {areaList.map((area) => <option key={area.id} value={area.id}>{area.name}</option>)}
                            </Form.Select>
                          </Col>
                        </Col>
                        
                      </Row>

                    </Container>
                  </Card.Body>
                </Card>

                <Row className="justify-content-between">
                  <Col lg={6} className="ps-lg-4 my-5text-lg-start mt-4">
                    <h3 className="text-primary">Seguimiento de Equipos</h3>
                  </Col>
                </Row>
                <Card className="h-md-100">
                  <Card.Body>
                  <AdvanceTableWrapper
                  columns={columns}
                  data={equipmentToTable}
                  sortable
                  pagination
                  perPage={5}
                >
                  <AdvanceTable
                    title='Equipos'
                    subtitle='Tabla de Equipos'
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  <div className="mt-3">
                    <AdvanceTableFooter
                      rowCount={equipmentList.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
                  </Card.Body>
                </Card>
          </>
        ) }
      </Card.Body>
  );
};

export default EquiposCliente;
