import { useEffect, useState } from 'react'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { columns } from './data'
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { useParams, useNavigate } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import { NuevoMensaje } from './NuevoMensaje';
import EditarMensaje from './EditarMensaje';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import moment from 'moment';
import TipoPerfilEnum from 'utils/TipoPerfilEnum';
import BitacoraData from 'pages/common/components/Bitacora'
import { toastNotification } from 'helpers/utils';
import { TablaArchivos } from 'pages/common/components';
import { DetalleMensaje } from './DetalleMensaje';
import useLoadingContext from 'hooks/useLoadingContext';

const idEntidad = 25;
let idDatoBitacora = 0;

export const TablaMensajes = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoadingContext();
  const { user } = useAuth()
  let params = useParams();

  const [ readyContent, setReadyContent ] = useState(false);
  const [ showMessageModal, setShowMessageModal ] = useState(false);
  const [ showEditMessageModal, setShowEditMessageModal ] = useState(false);
  const [ showDeleteMessageModal, setShowDeleteMessageModal ] = useState(false);
  const [ showFilesModal, setShowFilesModal ] = useState(false);
  const [ deleteMessage, setDeleteMessage ] = useState();
  const [ editarMessage, setEditarMessage ] = useState();
  const [ messages, setMessages] = useState([]);
  const [ files, setFiles ] = useState([]);

  const [ showDetailMessageModal, setShowDetailMessageModal ] = useState(false);
  const [ messageToDetail, setMessageToDetail ] = useState('');

  //? Bitacora
  const [ showBitacora, setShowBitacora ] = useState(false);

  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    !hasAccess ? navigate("/cliente/inicio") : startResetApp();
  }, [])

  const startResetApp = () => {
    getMessages();
  }

  const getMessages = async () => {

    let isAdmin = user.idTypeProfile == TipoPerfilEnum.administrador ? true : false;

    if (user != null){
      await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/message/getMessageByEquipment/${params.id}`).then((response) => {
        if( response.data.length === 0 ){
          setMessages([]);
          setReadyContent(true);
          return;
        }
        const list = response.data.data;
        list.forEach((element) => {
          element.date_message = moment(element.date_message).format('DD-MM-YYYY');
          element.category = element.categoria_mensaje != null ? element.categoria_message.descripcion : 'Sin Categoría';
          element.accion =  [
            
          <IconButton
            title="Eliminar"
            variant="white"
            size="sm"
            icon="trash-alt"
            style={{ 
              width: isAdmin ? '20%' : '',
              boxShadow: 'none',
              display: isAdmin ? '' : 'none'
            }}
            onClick={() => {
              setDeleteMessage(element.id);
              setShowDeleteMessageModal(true);
            }}
          ></IconButton>,
          <IconButton
            title="Ver Mensaje"
            variant="white"
            size="sm"
            icon="eye"
            style={{ 
              width: isAdmin ? '20%' : '50%',
              boxShadow: 'none',
            }}
            onClick={() => {
              setShowDetailMessageModal(true)
              setMessageToDetail(element)
            }}
          ></IconButton>,
          <IconButton
            title="Adjuntos"
            variant="white"
            size="sm"
            icon="paperclip"
            style={{ 
              width: isAdmin ? '20%' : '50%',
              boxShadow: 'none',
            }}
            onClick={() => {
              setShowFilesModal(true);
              setFiles(element.files)
            }}
          ></IconButton>,
          <IconButton
            title="Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ 
              width: isAdmin ? '20%' : '',
              boxShadow: 'none',
              display: isAdmin ? '' : 'none'
            }}
            onClick={() => {
              setShowBitacora(true);
              idDatoBitacora = element.id;
            }}
          ></IconButton>,
          ];
        });
      
        setMessages(orderDataMessages(list))
        setReadyContent(true);
    })
    .catch(e => {
      setReadyContent(true);
    });
  }
}

  //! SOLUCION PARCHE - Esta funcion será eliminada en las proximas versiones de promanet
  const orderDataMessages = ( messages = [] ) => {
    
    return messages.sort((a, b) => {
      const order = { 1: 1, 3: 2, 2: 3 };
      return order[a.idTypeMessage] - order[b.idTypeMessage];
    });

  };
    

  const handleDeleteMessage = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.delete(`${process.env.REACT_APP_EQUIPO_SERVICE}/message/${deleteMessage}`);
      if( resp.status === 200 ){
        startResetApp();
        setShowDeleteMessageModal(false);
        toastNotification('success', 'El mensaje se ha eliminado correctamente', 2000);
      }    
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toastNotification('error', 'Ocurrio un problema al intentar eliminar el mensaje', 2000);
    }
  };

  return (
    <>
    { !readyContent ? 
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
    : (
    <div>
      <AdvanceTableWrapper
        columns={columns}
        data={messages}
        sortable
        pagination
        perPage={5}
      >
        <Row>
          <Col xs={12} className='mx-auto mb-2'>
            { user.idTypeProfile == TipoPerfilEnum.cliente && (
              <div id="new-message">
                <IconButton
                  variant="falcon-success"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => setShowMessageModal(!showMessageModal)}
                >
                  Nuevo Mensaje
                </IconButton>
              </div>
            ) }
          </Col>
        </Row>
        <AdvanceTable
          title='Mensajes'
          subtitle='Tabla de Mensajes'
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={messages.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
      </div>
    )}
      { user.idTypeProfile == TipoPerfilEnum.cliente && (
        <>
          <NuevoMensaje key={1} startResetApp={startResetApp} showMessageModal={showMessageModal} setShowMessageModal={setShowMessageModal} />
          <EditarMensaje key={2} messageToEdit={editarMessage} setMessageToEdit={setEditarMessage} startResetApp={startResetApp} showEditMessageModal={showEditMessageModal} setShowEditMessageModal={setShowEditMessageModal} />

        </>
        

      ) }

      <Modal
        show={showDeleteMessageModal}
        onHide={() => setShowDeleteMessageModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro que quiere eliminar el mensaje?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteMessageModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button disabled={isLoading} variant="danger" onClick={handleDeleteMessage}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <DetalleMensaje key={3} mensaje={messageToDetail} setShowDetailMensajeModal={setShowDetailMessageModal} showDetailMensajeModal={showDetailMessageModal} />

      <div>
        <Modal
          show={showFilesModal}
          onHide={() => setShowFilesModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>Archivos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <TablaArchivos key={'archivos'} archivos={files} />

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowFilesModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      
      { user.idTypeProfile !== TipoPerfilEnum.cliente && (
          <BitacoraData 
            title="Bitacora del Mensaje" 
            titleToExport="Bitácora"
            subtitleToExport="Tabla de Bitácora"
            showBitacoraData={showBitacora} 
            setShowBitacoraData={setShowBitacora} 
            idData={idDatoBitacora}
            idEntidad={idEntidad}
          />
      ) }

    </>
  );
}

export default TablaMensajes;