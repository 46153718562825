import React, { useEffect, useState } from 'react'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsBitacora } from './data'
import axios from 'utils/axios';
import moment from 'moment';

export const BitacoraEquipo = () => {

  const [ bitacoraGeneral, setBitacoraGeneral] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/bitacora/getBitacoraEquipoByIdUser`;
    axios.get(url).then((response) => {
      if(response.data.statusCode === 200){
        const list =  response.data.data;
        list.forEach(element => {
          element.nombre_evento = element.typeEvent.nombre;
          element.nombre_usuario = element.user.name;
          element.apellido_usuario = element.user.lastName;
          element.fecha = moment(element.fecha_transaccion).format('DD-MM-YYYY');
          element.hora = moment(element.fecha_transaccion).format('hh:mm:ss');
        });
        setBitacoraGeneral(list);
      }
    });
  }, []);

  return (
    <>
      { !bitacoraGeneral.length > 0 ? (
          <h6>No hay historial en la Bitácora</h6>
        ) :
        (
          <div>
            <AdvanceTableWrapper
              columns={columnsBitacora}
              data={bitacoraGeneral}
              sortable
              pagination
              perPage={10}
            >
              <AdvanceTable
                title='Bítacora de Equipo'
                subtitle='Tabla de Bítacora de Equipos'
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={bitacoraGeneral.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </div>
        )
      }
    </>
  )
}
